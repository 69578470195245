import { useSelector, useDispatch } from "react-redux";
import { injectComponents } from "kepler.gl/components";
import { addDataToMap, updateMap } from "kepler.gl/actions";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import { useEffect } from "react";
import clsx from "clsx";
import "../../src/App.css";
import { selectActiveTab } from "../redux/selectors/appSelector";
import { DESIGN_DATASET_INFO, KEPLER_ID, UiTabs } from "../constants";
import {
  selectProjectData,
  selectResistivityProfiles,
} from "../redux/selectors/apiSelectors";
import {
  closeResistivityProfile,
  fetchSaveConfiguration,
  raiseResistivityProfile,
  toggleResistivityProfileSize,
} from "../redux/actions/apiActions";
import { mapStyle as useStyles } from "../helper/cssHelper";
import { replaceMapControlFactory } from "../factiories/MapControlFactory";
import { ResistivityProfiles } from "./ResistivityProfiles";
import { replaceSourceDataCatalogFactory } from "../factiories/SourceDataCatalogFactory";
import { replaceDatasetTitleFactory } from "../factiories/DatasetTitleFactory";

const KeplerGl = injectComponents([
  replaceMapControlFactory(),
  replaceSourceDataCatalogFactory(),
  replaceDatasetTitleFactory(),
]);

const Map = ({ drawerOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activeTab = useSelector(selectActiveTab);
  const projectData = useSelector(selectProjectData);
  const resistivityProfiles = useSelector(selectResistivityProfiles);

  useEffect(() => {
    const { config, extraDatasets, designDatasetData } = projectData;
    if (designDatasetData) {
      dispatch(
        addDataToMap({
          datasets: [
            {
              info: DESIGN_DATASET_INFO,
              data: designDatasetData,
            },
            ...extraDatasets,
          ],
          option: {
            centerMap: true,
            readOnly: false,
          },
          config,
        })
      );
    }
    if (config) {
      dispatch(updateMap(config.mapState));
    }
  }, [dispatch, projectData]);

  useEffect(() => {
    const beforeUnloadHandler = (e) => {
      dispatch(fetchSaveConfiguration());
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [dispatch]);

  return (
    <div
      className={clsx(classes.mapWithDrawer, {
        [classes.mapWithDrawerOpen]: drawerOpen,
        [classes.mapWithDrawerClose]: !drawerOpen,
      })}
      style={{
        display: activeTab === UiTabs.MAP ? "flex" : "none",
      }}
    >
      <ResistivityProfiles
        resistivityProfiles={resistivityProfiles}
        onCloseResistivityProfile={(locationId) => {
          dispatch(closeResistivityProfile(locationId));
        }}
        onRaiseProfile={(locationId) => {
          dispatch(raiseResistivityProfile(locationId));
        }}
        onSizeToggeResistivityProfile={(locationId) => {
          dispatch(toggleResistivityProfileSize(locationId));
        }}
      />
      <AutoSizer>
        {({ height, width }) => (
          <KeplerGl
            appName="Kepler"
            id={KEPLER_ID}
            mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
            width={width}
            height={height}
          />
        )}
      </AutoSizer>
    </div>
  );
};
export default Map;
