import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchAuth0Token,
  setActiveProject,
  setActiveTab,
} from "../redux/actions/appActions";
import { projectStyle as useStyles } from "../helper/cssHelper";
import { UiTabs } from "../constants";
import {
  selectIsProjectLoadingSucceeded,
  selectProjects,
} from "../redux/selectors/apiSelectors";
import {
  selectActiveTab,
  selectAuth0Token,
} from "../redux/selectors/appSelector";
import {
  fetchDesign,
  fetchProject,
  fetchProjects,
} from "../redux/actions/apiActions";
import Progress from "./Progress";

const Projects = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: projects } = useSelector(selectProjects);
  const activeTab = useSelector(selectActiveTab);
  const token = useSelector(selectAuth0Token);
  const isProjectLoadingSucceeded = useSelector(
    selectIsProjectLoadingSucceeded
  );

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    dispatch(fetchAuth0Token(getAccessTokenSilently));
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (token) {
      dispatch(fetchProjects());
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (isProjectLoadingSucceeded) {
      dispatch(setActiveTab(UiTabs.MAP));
    }
  }, [dispatch, isProjectLoadingSucceeded]);

  const onClickProjectHandler = (data) => {
    dispatch(setActiveProject(data));
    dispatch(fetchProject());
    dispatch(fetchDesign());
  };

  return (
    <>
      <Progress />
      {activeTab === UiTabs.PROJECTS && (
        <div className={classes.container}>
          <Grid container spacing={2}>
            {Boolean(projects?.length) &&
              projects.map((project) => (
                <Grid key={project.name} item lg={2} md={3} sm={6} xs={12}>
                  <Card onClick={() => onClickProjectHandler(project)}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image={project.thumbnail}
                        title="Project"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          {project.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {project.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </>
  );
};
export default Projects;
