import styled from "styled-components";
import { IconButton, useTheme } from "@material-ui/core";
import { CHART_SIZE, RequestStatus } from "../../constants";

import CloseIcon from "@material-ui/icons/Close";
import ZoomOutMap from "@mui/icons-material/ZoomOutMap";
import ZoomInMap from "@mui/icons-material/ZoomInMap";

const Header = styled.div`
  z-index: 1;
  position: absolute;
  height: 34px;
  right: 3px;
  top: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  box-sizing: border-box;
`;

const DragHandle = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  ${({
    font: { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing },
  }) => `
    font-family: ${fontFamily};
    font-weight: ${fontWeight};
    font-size: ${fontSize};
    letter-spacing: ${letterSpacing};
  `};
`;

const Buttons = styled.div`
  height: 30px;
  width: 69px;
  display: flex;
  justify-content: flex-end;
`;

export const ResistivityProfileHeader = ({
  locationId,
  status,
  onClose,
  size,
  onSizeToggle,
}) => {
  const theme = useTheme();
  return (
    <Header>
      <DragHandle className="drag-handle" font={theme.typography.caption}>
        <div>Location {locationId} Resistivity Profile</div>
      </DragHandle>
      <Buttons>
        {status === RequestStatus.FAILED && (
          <IconButton size="small" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        {status === RequestStatus.SUCCEEDED && (
          <>
            <IconButton size="small" aria-label="close" onClick={onSizeToggle}>
              {size === CHART_SIZE.COLLAPSED && <ZoomOutMap />}
              {size === CHART_SIZE.EXPANDED && <ZoomInMap />}
            </IconButton>
            <IconButton size="small" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </>
        )}
      </Buttons>
    </Header>
  );
};
