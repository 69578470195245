import { useSelector } from "react-redux";
import { DatasetTitleFactory, withState } from "kepler.gl/components";
import { DESIGN_DATASET_INFO } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";
import { selectAdminDatasetIds } from "../redux/selectors/apiSelectors";

const CustomDatasetTitleFactory = (...deps) => {
  const DatasetTitle = DatasetTitleFactory(...deps);

  const WrappedDatasetTitle = (props) => {
    const adminDatasetIds = useSelector(selectAdminDatasetIds);
    const { user } = useAuth0();
    const { dataset } = props;
    let showDeleteDataset = true;
    // Design dataset cannot be deleted for everybody
    if (dataset.id === DESIGN_DATASET_INFO.id) {
      showDeleteDataset = false;
    }
    // admin datasets can be deleted only by admins
    else if (
      adminDatasetIds.includes(dataset.id) &&
      !user["https://terraspark.xyz/roles"].includes("admin")
    ) {
      showDeleteDataset = false;
    }
    return <DatasetTitle {...props} showDeleteDataset={showDeleteDataset} />;
  };

  return withState(
    [],
    (state) => {
      const componentState = {
        ...state.keplerGl,
      };
      return componentState;
    },
    {}
  )(WrappedDatasetTitle);
};

CustomDatasetTitleFactory.deps = DatasetTitleFactory.deps;

export function replaceDatasetTitleFactory() {
  return [DatasetTitleFactory, CustomDatasetTitleFactory];
}
