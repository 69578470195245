import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@material-ui/core";
import { defaultTheme } from "./themes/defaultTheme";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientID = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0Provider
          domain={domain}
          clientId={clientID}
          redirectUri={window.location.origin}
          audience={process.env.REACT_APP_AUDIENCE}
          scope="openid profile email"
        >
          <ThemeProvider theme={defaultTheme}>
            <App />
          </ThemeProvider>
        </Auth0Provider>
      </BrowserRouter>
    </Provider>
  </>,
  document.getElementById("root")
);
