import {
  DESIGN_DATASET_INFO,
  KEPLER_ID,
  LOCATION_DATASET_PREFIX,
  SELECTED_LOCATION_LABEL_PREFIX,
} from "../../constants";
import {
  addResistivityProfile,
  closeResistivityProfile,
  fetchResistivityProfile,
} from "./apiActions";
import {
  updateVisData,
  layerConfigChange,
  layerVisConfigChange,
} from "kepler.gl/actions";
import { hexToRgb } from "kepler.gl/dist/utils";

export const SET_ACTIVE_TAB = "APP::SET_ACTIVE_TAB";
export const SET_ACTIVE_PROJECT = "APP::SET_ACTIVE_PROJECT";
export const TOGGLE_SHOW_RESISTIVITY_PROFILES =
  "APP::TOGGLE_SHOW_RESISTIVITY_PROFILES";
export const SET_AUTH0_USER = "APP::SET_AUTH0_USER";
export const GET_AUTH0_TOKEN_LOADING = "APP::GET_AUTH0_TOKEN_LOADING";
export const GET_AUTH0_TOKEN_SUCCEEDED = "APP::GET_AUTH0_TOKEN_SUCCEEDED";
export const GET_AUTH0_TOKEN_FAILED = "APP::GET_AUTH0_TOKEN_FAILED";

export const setActiveTab = (tab) => {
  return {
    type: SET_ACTIVE_TAB,
    payload: tab,
  };
};

export const setActiveProject = (project) => {
  return {
    type: SET_ACTIVE_PROJECT,
    payload: project,
  };
};

export const toggleShowResistivityProfiles = () => {
  return {
    type: TOGGLE_SHOW_RESISTIVITY_PROFILES,
  };
};

export const setAuth0User = (user) => {
  return {
    type: SET_AUTH0_USER,
    payload: user,
  };
};

const getAuth0TokenLoading = (token) => {
  return {
    type: GET_AUTH0_TOKEN_LOADING,
    payload: token,
  };
};
const getAuth0TokenSucceeded = (token) => {
  return {
    type: GET_AUTH0_TOKEN_SUCCEEDED,
    payload: token,
  };
};
const getAuth0TokenFailed = (error) => {
  return {
    type: GET_AUTH0_TOKEN_FAILED,
    payload: error,
  };
};
export const fetchAuth0Token = (getAuth0TokenFunction) => async (dispatch) => {
  dispatch(getAuth0TokenLoading);
  try {
    const token = await getAuth0TokenFunction();
    dispatch(getAuth0TokenSucceeded(token));
  } catch (error) {
    dispatch(getAuth0TokenFailed(error));
  }
};

export const switchResistivityProfile =
  (info) => async (dispatch, getState) => {
    if (!info.layer || !Number.isFinite(info.index)) {
      return;
    }
    const layerId = info.layer.id;
    const state = getState();
    const { visState } = state.keplerGl[KEPLER_ID];
    const keplerLayer = visState.layers.find((layer) => layer.id === layerId);
    const dataId = keplerLayer.config.dataId;
    if (
      dataId !== DESIGN_DATASET_INFO.id &&
      !dataId.includes(LOCATION_DATASET_PREFIX)
    ) {
      return;
    }
    const dataset = visState.datasets[dataId];
    const locationId = dataset.getValue("location_id", info.object.index);

    const resistivityProfiles = state.api.resistivityProfiles;
    const profile = resistivityProfiles.find(
      (item) => item.locationId === locationId
    );
    const datasetId = `${LOCATION_DATASET_PREFIX}${locationId}`;
    if (profile) {
      dispatch(closeResistivityProfile(locationId));
    } else {
      dispatch(addResistivityProfile(locationId));
      dispatch(fetchResistivityProfile(locationId));
      const newProfileState = getState();
      const profiles = newProfileState.api.resistivityProfiles;
      const profile = profiles.find(
        ({ locationId: profileLocationId }) => profileLocationId === locationId
      );
      if (!profile) {
        return;
      }
      const color = hexToRgb(profile.color);
      dispatch(
        updateVisData([
          {
            info: {
              label: `Selected location ${locationId}`,
              id: datasetId,
            },
            data: {
              fields: [
                { name: "location_id", format: "", type: "integer" },
                { name: "lng", format: "", type: "real" },
                { name: "lat", format: "", type: "real" },
              ],
              rows: [
                [locationId, info.object.position[0], info.object.position[1]],
              ],
            },
          },
        ])
      );
      const state = getState();
      const layers = state.keplerGl?.[KEPLER_ID].visState.layers;
      const newLayerIndex = layers.findIndex(
        ({ config: { dataId } }) => dataId === datasetId
      );
      if (newLayerIndex !== -1) {
        const newLayer = layers[newLayerIndex];
        dispatch(
          layerConfigChange(newLayer, {
            color,
            highlightColor: color,
            label: `${SELECTED_LOCATION_LABEL_PREFIX}${locationId}`,
            hidden: true,
          })
        );
        dispatch(layerVisConfigChange(newLayer, { radius: 20 }));
      }
    }
  };
