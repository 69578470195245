import {
  GET_DELETED_DATA,
  GET_PROJECT_ID,
  GET_USER,
  USER_TOKEN,
} from "../constants/userConstants";

export const userReducer = (
  state = { user: {}, token: null, id: null, data: [] },
  action
) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case USER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case GET_PROJECT_ID:
      return {
        ...state,
        id: action.payload,
      };
    case GET_DELETED_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
