import { createTheme } from "@material-ui/core/styles";

export const defaultTheme = createTheme({
  palette: {
    type: "dark",
    background: {
      paper: "#242730",
      default: "#3A414C"
    },
  },
});
