import {
  Backdrop,
  Container,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RequestStatus } from "../constants";

import { loadingStyle as useStyles } from "../helper/cssHelper";
import { selectProjectLoadingProgress, selectIsProjectInLoading } from "../redux/selectors/apiSelectors";

const selectLoadingStep = createSelector(
  (state) => state.api.project.status,
  (state) => state.api.design.fetchingStatus,
  (state) => state.api.design.unpackingStatus,
  (fetchProjectStatus, fetchingDesignStatus, unpackingDesignStatus) => {
    if (fetchProjectStatus === RequestStatus.LOADING) {
      return "Opening Project";
    } else if (fetchingDesignStatus === RequestStatus.LOADING) {
      return "Fetching Designs";
    } else if (unpackingDesignStatus === RequestStatus.LOADING) {
      return "Unpacking";
    } else {
      return "Finish";
    }
  }
);

const Progress = () => {
  const classes = useStyles();
  const open = useSelector(selectIsProjectInLoading);
  const progress = useSelector(selectProjectLoadingProgress);
  const step = useSelector(selectLoadingStep);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Container maxWidth="sm">
        <Typography
          variant="subtitle1"
          display="block"
          gutterBottom
          align="center"
        >
          {step}
        </Typography>
        <LinearProgress variant="determinate" value={progress} />
      </Container>
    </Backdrop>
  );
};

export default Progress;
