import {
  MapControlFactory,
  withState,
} from "kepler.gl/components";
import { ResistivityProfilesButton } from "../components/ResistivityProfilesButton";
import { toggleShowResistivityProfiles } from "../redux/actions/appActions";

const CustomMapControlFactory = (...deps) => {
  const MapControl = MapControlFactory(...deps);

  const [
    MapDrawPanel,
    Toggle3dButton,
    SplitMapButton,
    MapLegendPanel,
    LayerSelectorPanel,
    LocalePanel,
  ] = deps;
  const actionComponents = [
    SplitMapButton,
    LayerSelectorPanel,
    Toggle3dButton,
    MapLegendPanel,
    MapDrawPanel,
    LocalePanel,
  ];
  actionComponents.push(ResistivityProfilesButton);

  return withState(
    [],
    (state) => ({
      ...state.keplerGl,
      actionComponents,
      showResistivityProfiles: state.app.showResistivityProfiles,
    }),
    {
      onResistivityProfilesClick: toggleShowResistivityProfiles
    }
  )(MapControl);
};

CustomMapControlFactory.deps = MapControlFactory.deps;

export function replaceMapControlFactory() {
  return [MapControlFactory, CustomMapControlFactory];
}
