import { createSelector } from "reselect";
import { UiTabs } from "../../constants";

export const selectActiveTab = createSelector(
  (state) => {
    return state.app.activeTab;
  },
  (result) => result
);

export const selectAuth0Token = createSelector(
  (state) => {
    return state.app.auth0Token.data;
  },
  (result) => result
);

export const selectAuth0User = createSelector(
  (state) => state.app.auth0User,
  (result) => result
);

export const selectAppTitle = createSelector(
  (state) => state.app.activeTab,
  (state) => state.app.activeProject?.name,
  (activeTab, projectName) => {
    let page = "Home";
    if (activeTab === UiTabs.MAP) {
      page = projectName || "";
    }
    return `Terraspark - ${page}`;
  }
);
