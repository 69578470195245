import { useTheme } from "@material-ui/core";
import Draggable from "react-draggable";
import styled from "styled-components";
import { ResistivityProfile } from "./resistivity-profile/ResistivityProfile";

const OFFSET = 40;

const ResistivityProfilesContainer = styled.div`
  position: absolute;
  right: 60px;
  top: 12px;
  z-index: 5;
  visibility: hidden;
`;

const ResistivityProfileContainer = styled.div`
  position: absolute;
  right: 0;
  top: ${({ index }) => OFFSET * index}px;
  visibility: visible;
`;

export const ResistivityProfiles = ({
  resistivityProfiles,
  onCloseResistivityProfile,
  onRaiseProfile,
  onSizeToggeResistivityProfile,
}) => {
  const theme = useTheme();

  return (
    <ResistivityProfilesContainer
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={1}
    >
      {resistivityProfiles.map(
        ({ locationId, status, data, color, size, index: profileIndex }) => {
          return (
            <Draggable
              key={locationId}
              handle=".drag-handle"
              onMouseDown={() => onRaiseProfile(locationId)}
            >
              <ResistivityProfileContainer
                item
                xs={3}
                theme={theme}
                index={profileIndex}
              >
                <ResistivityProfile
                  locationId={locationId}
                  status={status}
                  data={data}
                  color={color}
                  size={size}
                  onClose={() => onCloseResistivityProfile(locationId)}
                  onSizeToggle={() => onSizeToggeResistivityProfile(locationId)}
                />
              </ResistivityProfileContainer>
            </Draggable>
          );
        }
      )}
    </ResistivityProfilesContainer>
  );
};
