import axios from "axios";
import {
  API_DATASETS,
  API_DESIGNS,
  API_PROJECTS,
  API_PROJECTS_ALL,
  API_PROJECTS_SAVE_CONFIGURATION,
  API_PROJECTS_THUMBNAIL,
  API_RESISTIVITY_PROFILE,
} from "./constants";

export const getAllProjects = async (token) => {
  return axios.get(`${process.env.REACT_APP_BACKEND_URL}/${API_PROJECTS_ALL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectsById = async (projectId, token) => {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/${API_PROJECTS}/${projectId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getThumbnailByProjectId = async (projectId, token) => {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/${API_PROJECTS_THUMBNAIL}/${projectId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    }
  );
};

export const getDesignByProjectId = async (projectId, token) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_URL}/${API_DESIGNS}/${projectId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const postSaveConfiguration = async (project_id, data, token) => {
  axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/${API_PROJECTS_SAVE_CONFIGURATION}/${project_id}`,
    JSON.stringify(data),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const postDatasets = async (project_id, version, data, token) => {
  axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/${API_DATASETS}/${project_id}`,
    JSON.stringify(
      data.map((data) => ({
        version,
        data,
      }))
    ),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteDatasets = async (project_id, datasetIds, token) => {
  await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/${API_DATASETS}/${project_id}`,
    {
      data: datasetIds,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getResistivityProfile = async (projectId, locationId, token) => {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/${API_RESISTIVITY_PROFILE}/${projectId}/${locationId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
