export const KEPLER_ID = "KeplerGL";

export const DESIGN_DATASET_INFO = {
  label: "Array Data",
  id: "array_data",
};

export const LOCATION_DATASET_PREFIX = "location_data_";
export const SELECTED_LOCATION_LABEL_PREFIX = "Location ";

export const RequestStatus = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  SUCCEEDED: "SUCCEEDED",
  FAILED: "FAILED",
};

export const UiTabs = {
  PROJECTS: "PROJECTS",
  MAP: "MAP",
};

export const CHART_SIZE = {
  EXPANDED: "EXPANDED",
  COLLAPSED: "COLLAPSED",
};

export const designDatasetTemplate = () => ({
  fields: [
    { name: "location_id", format: "", type: "integer" },
    { name: "top_depth", format: "", type: "real" },
    { name: "design_specifications_id", format: "", type: "string" },
    { name: "pipeline_voltage", format: "", type: "real" },
    { name: "pipeline_half_space_voltage", format: "", type: "real" },
    { name: "cable_run_length", format: "", type: "integer" },
    { name: "cable_resistance", format: "", type: "real" },
    { name: "contact_resistance", format: "", type: "real" },
    { name: "earth_resistance", format: "", type: "real" },
    { name: "dwight_resistance", format: "", type: "real" },
    { name: "loop_resistance", format: "", type: "real" },
    { name: "driving_voltage", format: "", type: "real" },
    { name: "power_consumption", format: "", type: "integer" },
    { name: "lat", format: "", type: "real" },
    { name: "lon", format: "", type: "real" },
    { name: "offset", format: "", type: "real" },
    { name: "pipeline_km_marker", format: "", type: "integer" },
  ],
  rows: [],
});
