import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import MapIcon from "@material-ui/icons/Map";
import Projects from "./Projects";
import Map from "./Map";
import { mainScreenStyle as useStyles } from "../helper/cssHelper";
import { UiTabs } from "../constants";
import { setActiveTab } from "../redux/actions/appActions";
import { fetchSaveConfiguration } from "../redux/actions/apiActions";
import { selectAppTitle } from "../redux/selectors/appSelector";
import Loading from "./Loading";
import styled from "styled-components";

const CustomAvatar = styled(Avatar)`
  cursor: pointer;
`;

const MENU_ITEMS = [
  { id: UiTabs.PROJECTS, text: "Projects", IconComponent: HomeIcon },
  { id: UiTabs.MAP, text: "Map", IconComponent: MapIcon },
];

const MainScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const title = useSelector(selectAppTitle);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout } = useAuth0();

  useEffect(() => {
    document.title = title;
  }, [title]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const auth = window.localStorage.getItem("isAuth");

  const onClickMenuHandler = async (menuId) => {
    dispatch(setActiveTab(menuId));
    if (menuId !== UiTabs.MAP) {
      dispatch(fetchSaveConfiguration());
    }
  };

  const logoutAuth = () => {
    logout({ returnTo: `http://${process.env.REACT_APP_SERVER_URL}` });
    window.localStorage.removeItem("token");
  };

  return (
    <>
      <Loading />
      {auth && (
        <div className={classes.root}>
          <Drawer
            style={{ backgroundColor: "#000" }}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <div className={classes.toolbar}>
              {drawerOpen && (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              )}
              {!drawerOpen && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: drawerOpen,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </div>
            <Divider />
            <List>
              {MENU_ITEMS.map(({ id, text, IconComponent }, index) => {
                return (
                  <ListItem
                    button
                    key={index}
                    onClick={() => onClickMenuHandler(id)}
                  >
                    <ListItemIcon>
                      <IconComponent />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                );
              })}
            </List>
            <div style={{ position: "absolute", bottom: 7, left: 8 }}>
              <CustomAvatar
                src={user && user.picture}
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick}
              ></CustomAvatar>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={logoutAuth}>Logout</MenuItem>
              </Menu>
            </div>
          </Drawer>

          <main className={classes.content}>
            <Projects />
            <Map drawerOpen={drawerOpen} />
          </main>
        </div>
      )}
    </>
  );
};
export default MainScreen;
