import classnames from "classnames";
import { MapControlTooltipFactory } from "kepler.gl/components";
import { MapControlButton } from "kepler.gl/dist/components/common/styled-components";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import styled from "styled-components";

const LineAxisIconRotated = styled(LineAxisIcon)`
  transform: rotate(90deg);
`;

const MapControlTooltip = MapControlTooltipFactory();
export const ResistivityProfilesButton = ({showResistivityProfiles, onResistivityProfilesClick}) => {
  return (
    <MapControlButton
      active={showResistivityProfiles}
      onClick={onResistivityProfilesClick}
      key="resistivity-profiles-button"
      className={classnames("map-control-button", "split-map")}
      data-tip
      data-for="resistivity-profiles-tooltip"
    >
      <LineAxisIconRotated />
      <MapControlTooltip
        id="resistivity-profiles-tooltip"
        message={"Resistivity Profiles"}
      />
    </MapControlButton>
  );
};
