import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RequestStatus } from "../constants";

import { loadingStyle as useStyles } from "../helper/cssHelper";

const selectLoadingOpen = createSelector(
  (state) => state.api.projects.status,
  (state) => state.api.saveConfiguration.status,
  (state) => state.api.addDataset.status,
  (state) => state.api.deleteDatasets.status,
  (...statuses) => {
    return statuses.includes(RequestStatus.LOADING);
  }
);

const Loading = () => {
  const classes = useStyles();
  const open = useSelector(selectLoadingOpen);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress />
    </Backdrop>
  );
};

export default Loading;
