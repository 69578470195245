import { KeplerGlSchema } from "kepler.gl/schemas";
import { removeDataset } from "kepler.gl/actions";
import avro from "avsc";
import {
  deleteDatasets,
  getAllProjects,
  getDesignByProjectId,
  getProjectsById,
  getResistivityProfile,
  getThumbnailByProjectId,
  postDatasets,
  postSaveConfiguration,
} from "../../api/apiHandler";
import {
  designDatasetTemplate,
  LOCATION_DATASET_PREFIX,
} from "../../constants";
import { selectConfigToSave } from "../selectors/keplerSelectors";

export const GET_ALL_PROJECTS_LOADING = "API::GET_ALL_PROJECTS_LOADING";
export const GET_ALL_PROJECTS_SUCCEEDED = "API::GET_ALL_PROJECTS_SUCCEEDED";
export const GET_ALL_PROJECTS_FAILED = "API::GET_ALL_PROJECTS_FAILED";

export const GET_PROJECT_BY_ID_LOADING = "API::GET_PROJECT_BY_ID_LOADING";
export const GET_PROJECT_BY_ID_SUCCEEDED = "API::GET_PROJECT_BY_ID_SUCCEEDED";
export const GET_PROJECT_BY_ID_FAILED = "API::GET_PROJECT_BY_ID_FAILED";

export const GET_DESIGN_BY_PROJECT_ID_LOADING =
  "API::GET_DESIGN_BY_PROJECT_ID_LOADING";
export const GET_DESIGN_BY_PROJECT_ID_SUCCEEDED =
  "API::GET_DESIGN_BY_PROJECT_ID_SUCCEEDED";
export const GET_DESIGN_BY_PROJECT_ID_FAILED =
  "API::GET_DESIGN_BY_PROJECT_ID_FAILED";
export const GET_DESIGN_BY_PROJECT_ID_PROGRESS =
  "API::GET_DESIGN_BY_PROJECT_ID_PROGRESS";
export const GET_DESIGN_BY_PROJECT_ID_UNPACKING_LOADING =
  "API::GET_DESIGN_BY_PROJECT_ID_UNPACKING_LOADING";
export const GET_DESIGN_BY_PROJECT_ID_UNPACKING_SUCCEEDED =
  "API::GET_DESIGN_BY_PROJECT_ID_UNPACKING_SUCCEEDED";
export const GET_DESIGN_BY_PROJECT_ID_UNPACKING_FAILED =
  "API::GET_DESIGN_BY_PROJECT_ID_UNPACKING_FAILED";
export const GET_DESIGN_BY_PROJECT_ID_UNPACKING_PROGRESS =
  "API::GET_DESIGN_BY_PROJECT_ID_UNPACKING_PROGRESS";

export const POST_SAVE_CONFIGURATION_LOADING =
  "API::POST_SAVE_CONFIGURATION_LOADING";
export const POST_SAVE_CONFIGURATION_SUCCEEDED =
  "API::POST_SAVE_CONFIGURATION_SUCCEEDED";
export const POST_SAVE_CONFIGURATION_FAILED =
  "API::POST_SAVE_CONFIGURATION_FAILED";

export const POST_DATASETS_LOADING = "API::POST_DATASETS_LOADING";
export const POST_DATASETS_SUCCEEDED = "API::POST_DATASETS_SUCCEEDED";
export const POST_DATASETS_FAILED = "API::POST_DATASETS_FAILED";

export const DELETE_DATASETS_LOADING = "API::DELETE_DATASETS_LOADING";
export const DELETE_DATASETS_SUCCEEDED = "API::DELETE_DATASETS_SUCCEEDED";
export const DELETE_DATASETS_FAILED = "API::DELETE_DATASETS_FAILED";

export const ADD_RESISTIVITY_PROFILE = "API::ADD_RESISTIVITY_PROFILE";
export const DELETE_RESISTIVITY_PROFILE = "API::DELETE_RESISTIVITY_PROFILE";
export const GET_RESISTIVITY_PROFILE_LOADING =
  "API::GET_RESISTIVITY_PROFILE_LOADING";
export const GET_RESISTIVITY_PROFILE_SUCCEEDED =
  "API::GET_RESISTIVITY_PROFILE_SUCCEEDED";
export const GET_RESISTIVITY_PROFILE_FAILED =
  "API::GET_RESISTIVITY_PROFILE_FAILED";
export const RAISE_RESISTIVITY_PROFILE = "API::RAISE_RESISTIVITY_PROFILE";
export const TOGGLE_RESISTIVITY_PROFILE_SIZE =
  "API::TOGGLE_RESISTIVITY_PROFILE_SIZE";

const NO_AUTH0_TOKEN_ERROR = "Token is not set";
const NO_PROJECT_SELECTED = "No project selected";

const getAllProjectsLoading = () => {
  return {
    type: GET_ALL_PROJECTS_LOADING,
  };
};
const getAllProjectsSucceeded = (data) => {
  return {
    type: GET_ALL_PROJECTS_SUCCEEDED,
    payload: data,
  };
};
const getAllProjectsFailed = (error) => {
  return {
    type: GET_ALL_PROJECTS_FAILED,
    payload: error,
  };
};
export const fetchProjects = () => async (dispatch, getState) => {
  dispatch(getAllProjectsLoading());
  try {
    const state = getState();
    const auth0Token = state.app.auth0Token.data;
    if (!auth0Token) {
      throw new Error(NO_AUTH0_TOKEN_ERROR);
    }
    const response = await getAllProjects(auth0Token);
    const promises = response.data.map(({ id }) =>
      getThumbnailByProjectId(id, auth0Token)
    );
    const thumbnailResponses = await Promise.all(promises);
    for (let i = 0; i < response.data.length; i++) {
      const thumbnailRespoinse = thumbnailResponses[i];
      response.data[i].thumbnail = `data:${
        thumbnailRespoinse.headers["content-type"]
      };base64,${Buffer.from(thumbnailRespoinse.data, "binary").toString(
        "base64"
      )}`;
    }
    dispatch(getAllProjectsSucceeded(response.data));
  } catch (error) {
    dispatch(getAllProjectsFailed(error));
  }
};

const getProjectByIdLoading = () => {
  return {
    type: GET_PROJECT_BY_ID_LOADING,
  };
};
const getProjectByIdSucceeded = (postProcessedData) => {
  return {
    type: GET_PROJECT_BY_ID_SUCCEEDED,
    payload: postProcessedData,
  };
};
const getProjectByIdFailed = (error) => {
  return {
    type: GET_PROJECT_BY_ID_FAILED,
    payload: error,
  };
};
export const fetchProject = () => async (dispatch, getState) => {
  dispatch(getProjectByIdLoading());
  try {
    const state = getState();
    const auth0Token = state.app.auth0Token.data;
    if (!auth0Token) {
      throw new Error(NO_AUTH0_TOKEN_ERROR);
    }
    const projectId = state.app.activeProject.id;
    if (!projectId) {
      throw new Error(NO_PROJECT_SELECTED);
    }
    const response = await getProjectsById(projectId, auth0Token);
    const { data } = response;
    let parsedConfig = null;
    if (data) {
      const { configuration } = data || {};
      if (configuration) {
        parsedConfig = KeplerGlSchema.parseSavedConfig(configuration);
      }
    }
    let adminDatasets = [];
    if (data?.admin_datasets?.length) {
      adminDatasets = data?.admin_datasets.map((item) => item.data);
    }
    let userDatasets = [];
    if (data?.user_datasets?.length) {
      userDatasets = data?.user_datasets.map((item) => item.data);
    }
    dispatch(
      getProjectByIdSucceeded({
        data,
        parsedConfig,
        adminDatasets,
        userDatasets,
      })
    );
  } catch (error) {
    dispatch(getProjectByIdFailed(error));
  }
};

const getDesignByProjectIdLoading = () => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_LOADING,
  };
};
const getDesignByProjectIdSucceeded = () => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_SUCCEEDED,
  };
};
const getDesignByProjectIdFailed = (error) => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_FAILED,
    payload: error,
  };
};
const getDesignByProjectIdPercentage = (value) => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_PROGRESS,
    payload: value,
  };
};
const getDesignByProjectIdUnpackingLoading = () => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_UNPACKING_LOADING,
  };
};
const getDesignByProjectIdUnpackigSucceeded = (data) => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_UNPACKING_SUCCEEDED,
    payload: data,
  };
};
const getDesignByProjectIdUnpackingFailed = (error) => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_UNPACKING_FAILED,
    payload: error,
  };
};
const getDesignByProjectIdUnpackingPercentage = (value) => {
  return {
    type: GET_DESIGN_BY_PROJECT_ID_UNPACKING_PROGRESS,
    payload: value,
  };
};
export const fetchDesign = () => async (dispatch, getState) => {
  let blob;

  await dispatch(resetResistivityProfiles());

  // Fetch avro data
  dispatch(getDesignByProjectIdLoading());
  let avroRowCount;
  try {
    const state = getState();
    const auth0Token = state.app.auth0Token.data;
    if (!auth0Token) {
      throw new Error(NO_AUTH0_TOKEN_ERROR);
    }
    const projectId = state.app.activeProject.id;
    if (!projectId) {
      throw new Error(NO_PROJECT_SELECTED);
    }
    const response = await getDesignByProjectId(projectId, auth0Token);
    const contentLength = response.headers.get("content-length");
    avroRowCount = parseInt(response.headers.get("x-avrorowcount"));
    const downloadResponse = new Response(
      new ReadableStream({
        async start(controller) {
          const reader = response.body.getReader();
          let bytesReceived = 0;

          for (;;) {
            const { done, value } = await reader.read();
            if (done) break;
            bytesReceived += value.length;
            const itotal_percentage = (bytesReceived * 100) / contentLength;
            controller.enqueue(value);
            if (itotal_percentage <= 100) {
              dispatch(getDesignByProjectIdPercentage(itotal_percentage));
            }
          }
          controller.close();
        },
      })
    );

    blob = await downloadResponse.blob();
    dispatch(getDesignByProjectIdSucceeded());
  } catch (error) {
    dispatch(getDesignByProjectIdFailed(error));
  }

  // Decode avro
  dispatch(getDesignByProjectIdUnpackingLoading());
  try {
    let array = [];
    avro
      .createBlobDecoder(blob)
      .on("data", (data) => {
        if (Number.isFinite(avroRowCount)) {
          const percentage = (array.length * 100) / avroRowCount;
          const state = getState();
          // Increase by 5% to prevent many rerenders
          if (Math.abs(percentage - state.api.design.unpackingProgress) > 5) {
            dispatch(getDesignByProjectIdUnpackingPercentage(percentage));
          }
        }
        array.push([
          data["location_id"],
          data["top_depth"].toFixed(2),
          data["design_specifications_id"],
          data["pipeline_voltage"].toFixed(2),
          data["pipeline_half_space_voltage"].toFixed(3),
          data["cable_run_length"].toFixed(2),
          data["cable_resistance"].toFixed(2),
          data["contact_resistance"].toFixed(3),
          data["earth_resistance"].toFixed(2),
          data["dwight_resistance"].toFixed(3),
          data["loop_resistance"].toFixed(2),
          data["driving_voltage"].toFixed(2),
          data["power_consumption"].toFixed(0),
          data["lat"],
          data["lon"],
          data["offset"].toFixed(2),
          data["kilometer_point"].toFixed(2),
        ]);
      })
      .on("end", (data) => {
        const newDesignData = designDatasetTemplate();
        newDesignData.rows = array;
        dispatch(getDesignByProjectIdUnpackigSucceeded(newDesignData));
      });
  } catch (error) {
    dispatch(getDesignByProjectIdUnpackingFailed(error));
  }
};

const postSaveConfigurationLoading = () => {
  return {
    type: POST_SAVE_CONFIGURATION_LOADING,
  };
};
const postSaveConfigurationSucceeded = (data) => {
  return {
    type: POST_SAVE_CONFIGURATION_SUCCEEDED,
  };
};
const postSaveConfigurationFailed = (error) => {
  return {
    type: POST_SAVE_CONFIGURATION_FAILED,
    payload: error,
  };
};
export const fetchSaveConfiguration = () => async (dispatch, getState) => {
  dispatch(postSaveConfigurationLoading());
  try {
    const state = getState();
    const auth0Token = state.app.auth0Token.data;
    if (!auth0Token) {
      throw new Error(NO_AUTH0_TOKEN_ERROR);
    }
    const configToSave = selectConfigToSave(state);
    const projectId = state.app.activeProject.id;
    if (!projectId) {
      throw new Error(NO_PROJECT_SELECTED);
    }
    await postSaveConfiguration(projectId, configToSave, auth0Token);
    dispatch(postSaveConfigurationSucceeded());
  } catch (error) {
    dispatch(postSaveConfigurationFailed(error));
  }
};

const postDatasetsLoading = () => {
  return {
    type: POST_DATASETS_LOADING,
  };
};
const postDatasetsSucceeded = () => {
  return {
    type: POST_DATASETS_SUCCEEDED,
  };
};
const postDatasetsFailed = (error) => {
  return {
    type: POST_DATASETS_FAILED,
    payload: error,
  };
};
export const fetchSaveDatasets =
  (datasetsToSave) => async (dispatch, getState) => {
    try {
      const state = getState();
      if (!datasetsToSave) {
        return;
      }
      dispatch(postDatasetsLoading());
      const auth0Token = state.app.auth0Token.data;
      if (!auth0Token) {
        throw new Error(NO_AUTH0_TOKEN_ERROR);
      }
      const configToSave = selectConfigToSave(state);
      const projectId = state.app.activeProject.id;
      if (!projectId) {
        throw new Error(NO_PROJECT_SELECTED);
      }
      await postDatasets(
        projectId,
        configToSave.version,
        datasetsToSave,
        auth0Token
      );
      dispatch(postDatasetsSucceeded());
    } catch (error) {
      dispatch(postDatasetsFailed(error));
    }
  };

const deleteDatasetsLoading = () => {
  return {
    type: DELETE_DATASETS_LOADING,
  };
};
const deleteDatasetsSucceeded = () => {
  return {
    type: DELETE_DATASETS_SUCCEEDED,
  };
};
const deleteDatasetsFailed = (error) => {
  return {
    type: DELETE_DATASETS_FAILED,
    payload: error,
  };
};
export const fetchDeleteDatasets =
  (datasetIds) => async (dispatch, getState) => {
    dispatch(deleteDatasetsLoading());
    try {
      const state = getState();
      const auth0Token = state.app.auth0Token.data;
      if (!auth0Token) {
        throw new Error(NO_AUTH0_TOKEN_ERROR);
      }
      const projectId = state.app.activeProject.id;
      if (!projectId) {
        throw new Error(NO_PROJECT_SELECTED);
      }
      await deleteDatasets(projectId, datasetIds, auth0Token);
      dispatch(deleteDatasetsSucceeded());
    } catch (error) {
      dispatch(deleteDatasetsFailed(error));
    }
  };

export const addResistivityProfile = (locationId) => {
  return {
    type: ADD_RESISTIVITY_PROFILE,
    payload: locationId,
  };
};
const deleteResistivityProfile = (locationId) => {
  return {
    type: DELETE_RESISTIVITY_PROFILE,
    payload: locationId,
  };
};
const getResistivityProfileLoading = (locationId) => {
  return {
    type: GET_RESISTIVITY_PROFILE_LOADING,
    payload: locationId,
  };
};
const getResistivityProfileSucceeded = (locationId, data) => {
  return {
    type: GET_RESISTIVITY_PROFILE_SUCCEEDED,
    payload: { locationId, data },
  };
};
const getResistivityProfileFailed = (locationId, error) => {
  return {
    type: GET_RESISTIVITY_PROFILE_FAILED,
    payload: { locationId, error },
  };
};
export const fetchResistivityProfile =
  (locationId) => async (dispatch, getState) => {
    dispatch(getResistivityProfileLoading(locationId));
    const state = getState();
    try {
      const auth0Token = state.app.auth0Token.data;
      if (!auth0Token) {
        throw new Error(NO_AUTH0_TOKEN_ERROR);
      }
      const projectId = state.app.activeProject.id;
      if (!projectId) {
        throw new Error(NO_PROJECT_SELECTED);
      }
      const response = await getResistivityProfile(
        projectId,
        locationId,
        auth0Token
      );
      const { data } = response;
      dispatch(getResistivityProfileSucceeded(locationId, data));
    } catch (error) {
      dispatch(getResistivityProfileFailed(locationId, error));
    }
  };
export const closeResistivityProfile = (locationId) => (dispatch, getState) => {
  dispatch(deleteResistivityProfile(locationId));

  const datasetId = `${LOCATION_DATASET_PREFIX}${locationId}`;
  dispatch(removeDataset(datasetId));
};
export const resetResistivityProfiles = () => async (dispatch, getState) => {
  const state = getState();
  const profiles = state.api.resistivityProfiles;
  for (const profile of profiles) {
    await dispatch(closeResistivityProfile(profile.locationId));
  }
};
export const raiseResistivityProfile = (locationId) => {
  return {
    type: RAISE_RESISTIVITY_PROFILE,
    payload: locationId,
  };
};
export const toggleResistivityProfileSize = (locationId) => {
  return {
    type: TOGGLE_RESISTIVITY_PROFILE_SIZE,
    payload: locationId,
  };
};
