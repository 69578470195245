import "./App.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import MainScreen from "./components/MainScreen";
import Projects from "./components/Projects";
import NotFound from "./components/NotFound";
import { Switch, Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { setAuth0User } from "./redux/actions/appActions";
function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAuth0();
  useEffect(() => {
    if (user) {
      dispatch(setAuth0User(user));
    }
  }, [dispatch, user]);
  useEffect(() => {
    window.localStorage.setItem("isAuth", isAuthenticated);
  }, [isAuthenticated]);
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={withAuthenticationRequired(MainScreen)}
      />
      <Route exact path="/map" component={Projects} />
      <Route component={NotFound} />
    </Switch>
  );
}
export default App;
