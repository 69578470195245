import { createStore, applyMiddleware, compose } from "redux";
import { enhanceReduxMiddleware } from "kepler.gl/middleware";
import thunk from "redux-thunk";
import RootReducer from "./reducers/rootReducer";
import { interceptActionMiddleware } from "./middlewares/interceptActionMiddleware";

const middlewares = enhanceReduxMiddleware([interceptActionMiddleware, thunk]);
const enhancers = [applyMiddleware(...middlewares)];

const store = createStore(RootReducer, {}, compose(...enhancers));

export default store;
