import { SourceDataCatalogFactory, withState } from "kepler.gl/components";
import { KEPLER_ID, LOCATION_DATASET_PREFIX } from "../constants";

const CustomSourceDataCatalogFactory = (...deps) => {
  const SourceDataCatalog = SourceDataCatalogFactory(...deps);

  return withState(
    [],
    (state) => {
      const datasets = state.keplerGl[KEPLER_ID].visState.datasets;
      const visibleDatasets = {};
      for (const key in datasets) {
        if (!key.includes(LOCATION_DATASET_PREFIX)) {
          visibleDatasets[key] = datasets[key];
        }
      }
      const componentState = {
        datasets: visibleDatasets,
        ...state.keplerGl,
        [KEPLER_ID]: {
          ...state.keplerGl[KEPLER_ID],
          visState: {
            ...state.keplerGl[KEPLER_ID].visState,
          },
        },
      };
      return componentState;
    },
    {}
  )(SourceDataCatalog);
};

CustomSourceDataCatalogFactory.deps = SourceDataCatalogFactory.deps;

export function replaceSourceDataCatalogFactory() {
  return [SourceDataCatalogFactory, CustomSourceDataCatalogFactory];
}
