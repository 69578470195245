import { handleActions } from "redux-actions";
import { CHART_SIZE, RequestStatus } from "../../constants";
import {
  ADD_RESISTIVITY_PROFILE,
  DELETE_DATASETS_FAILED,
  DELETE_DATASETS_LOADING,
  DELETE_DATASETS_SUCCEEDED,
  DELETE_RESISTIVITY_PROFILE,
  GET_ALL_PROJECTS_FAILED,
  GET_ALL_PROJECTS_LOADING,
  GET_ALL_PROJECTS_SUCCEEDED,
  GET_DESIGN_BY_PROJECT_ID_FAILED,
  GET_DESIGN_BY_PROJECT_ID_LOADING,
  GET_DESIGN_BY_PROJECT_ID_PROGRESS,
  GET_DESIGN_BY_PROJECT_ID_SUCCEEDED,
  GET_DESIGN_BY_PROJECT_ID_UNPACKING_FAILED,
  GET_DESIGN_BY_PROJECT_ID_UNPACKING_LOADING,
  GET_DESIGN_BY_PROJECT_ID_UNPACKING_PROGRESS,
  GET_DESIGN_BY_PROJECT_ID_UNPACKING_SUCCEEDED,
  GET_PROJECT_BY_ID_FAILED,
  GET_PROJECT_BY_ID_LOADING,
  GET_PROJECT_BY_ID_SUCCEEDED,
  GET_RESISTIVITY_PROFILE_FAILED,
  GET_RESISTIVITY_PROFILE_LOADING,
  GET_RESISTIVITY_PROFILE_SUCCEEDED,
  POST_DATASETS_FAILED,
  POST_DATASETS_LOADING,
  POST_DATASETS_SUCCEEDED,
  POST_SAVE_CONFIGURATION_FAILED,
  POST_SAVE_CONFIGURATION_LOADING,
  POST_SAVE_CONFIGURATION_SUCCEEDED,
  RAISE_RESISTIVITY_PROFILE,
  TOGGLE_RESISTIVITY_PROFILE_SIZE,
} from "../actions/apiActions";

const INITIAL_STATE = {
  projects: {
    data: [],
    status: RequestStatus.IDLE,
    error: null,
  },
  project: {
    data: {},
    parsedConfig: null,
    adminDatasets: [],
    userDatasets: [],
    status: RequestStatus.IDLE,
    error: null,
  },
  design: {
    data: null,
    fetchingStatus: RequestStatus.IDLE,
    progress: 0,
    unpackingStatus: RequestStatus.IDLE,
    unpackingProgress: 0,
    error: null,
  },
  saveConfiguration: {
    status: RequestStatus.IDLE,
    error: null,
  },
  addDataset: {
    status: RequestStatus.IDLE,
    error: null,
  },
  deleteDatasets: {
    status: RequestStatus.IDLE,
    error: null,
  },
  resistivityProfiles: [],
};

const DEFAULT_PROFILE_COLOR = "#231F20"; // Dark Gray
const profilesColors = [
  "#002D6D", // Blue 1
  "#FFAF50", // Orange
  "#380438", // Purple
  "#460000", // Red
  "#2D1457", // Violet
  "#D2AA1B", // Yellow
  "#1E3C00", // Green
  "#3F0B81", // Violet 2
  "#004156", // Mint
  "#DCABAE", // Pink
  "#01142F", // Blue 2
  "#810B44", // Purple 2
];
const getProfileIndex = (profiles) => {
  const indices = profiles.map(({ index }) => index);
  let index = 0;
  while (indices.includes(index)) {
    index++;
  }
  return index;
};

export const apiReducer = handleActions(
  {
    [GET_ALL_PROJECTS_LOADING]: (state) => {
      return {
        ...state,
        projects: {
          data: [],
          status: RequestStatus.LOADING,
          error: null,
        },
      };
    },
    [GET_ALL_PROJECTS_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        projects: {
          data: action.payload,
          status: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [GET_ALL_PROJECTS_FAILED]: (state, action) => {
      return {
        ...state,
        projects: {
          data: [],
          status: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },

    [GET_PROJECT_BY_ID_LOADING]: (state) => {
      return {
        ...state,
        project: {
          data: {},
          parsedConfig: null,
          adminDatasets: [],
          userDatasets: [],
          status: RequestStatus.LOADING,
          error: null,
        },
      };
    },
    [GET_PROJECT_BY_ID_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        project: {
          ...action.payload,
          status: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [GET_PROJECT_BY_ID_FAILED]: (state, action) => {
      return {
        ...state,
        project: {
          data: {},
          parsedConfig: null,
          adminDatasets: [],
          userDatasets: [],
          status: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },

    [GET_DESIGN_BY_PROJECT_ID_LOADING]: (state) => {
      return {
        ...state,
        design: {
          data: null,
          progress: 0,
          fetchingStatus: RequestStatus.LOADING,
          unpackingStatus: RequestStatus.IDLE,
          unpackingProgress: 0,
          error: null,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        design: {
          ...state.design,
          data: null,
          fetchingStatus: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_FAILED]: (state, action) => {
      return {
        ...state,
        design: {
          ...state.design,
          data: null,
          fetchingStatus: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_PROGRESS]: (state, action) => {
      return {
        ...state,
        design: {
          ...state.design,
          progress: action.payload,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_UNPACKING_LOADING]: (state) => {
      return {
        ...state,
        design: {
          ...state.design,
          unpackingStatus: RequestStatus.LOADING,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_UNPACKING_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        design: {
          ...state.design,
          unpackingStatus: RequestStatus.SUCCEEDED,
          data: action.payload,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_UNPACKING_FAILED]: (state, action) => {
      return {
        ...state,
        design: {
          ...state.design,
          unpackingStatus: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },
    [GET_DESIGN_BY_PROJECT_ID_UNPACKING_PROGRESS]: (state, action) => {
      return {
        ...state,
        design: {
          ...state.design,
          unpackingProgress: action.payload,
        },
      };
    },

    [POST_SAVE_CONFIGURATION_LOADING]: (state) => {
      return {
        ...state,
        saveConfiguration: {
          status: RequestStatus.LOADING,
          error: null,
        },
      };
    },
    [POST_SAVE_CONFIGURATION_SUCCEEDED]: (state) => {
      return {
        ...state,
        saveConfiguration: {
          status: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [POST_SAVE_CONFIGURATION_FAILED]: (state, action) => {
      return {
        ...state,
        saveConfiguration: {
          status: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },

    [POST_DATASETS_LOADING]: (state) => {
      return {
        ...state,
        addDataset: {
          status: RequestStatus.LOADING,
          error: null,
        },
      };
    },
    [POST_DATASETS_SUCCEEDED]: (state) => {
      return {
        ...state,
        addDataset: {
          status: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [POST_DATASETS_FAILED]: (state, action) => {
      return {
        ...state,
        addDataset: {
          status: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },

    [DELETE_DATASETS_LOADING]: (state) => {
      return {
        ...state,
        deleteDatasets: {
          status: RequestStatus.LOADING,
          error: null,
        },
      };
    },
    [DELETE_DATASETS_SUCCEEDED]: (state) => {
      return {
        ...state,
        deleteDatasets: {
          status: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [DELETE_DATASETS_FAILED]: (state, action) => {
      return {
        ...state,
        deleteDatasets: {
          status: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },

    [ADD_RESISTIVITY_PROFILE]: (state, action) => {
      let oldResistivityProfiles = state.resistivityProfiles;
      const index = oldResistivityProfiles.findIndex(
        (item) => item.locationId === action.payload
      );
      let newProfile;
      if (index !== -1) {
        newProfile = oldResistivityProfiles.splice(index, 1);
      } else {
        const colors = profilesColors.splice(0, 1);
        const color = colors?.[0] || DEFAULT_PROFILE_COLOR;
        newProfile = {
          locationId: action.payload,
          status: RequestStatus.IDLE,
          data: null,
          error: null,
          color,
          index: getProfileIndex(oldResistivityProfiles),
          size: CHART_SIZE.COLLAPSED,
        };
      }

      return {
        ...state,
        resistivityProfiles: [...oldResistivityProfiles, newProfile],
      };
    },
    [DELETE_RESISTIVITY_PROFILE]: (state, action) => {
      let oldResistivityProfiles = state.resistivityProfiles;
      const index = state.resistivityProfiles.findIndex(
        (item) => item.locationId === action.payload
      );
      if (index !== -1) {
        const removedProfiles = oldResistivityProfiles.splice(index, 1);
        const color = removedProfiles?.[0]?.color;
        if (color && color !== DEFAULT_PROFILE_COLOR) {
          profilesColors.push(color);
        }
      }
      return {
        ...state,
        resistivityProfiles: [...oldResistivityProfiles],
      };
    },
    [GET_RESISTIVITY_PROFILE_LOADING]: (state, action) => {
      const index = state.resistivityProfiles.findIndex(
        (item) => item.locationId === action.payload
      );
      let oldResistivityProfiles = state.resistivityProfiles;
      if (index !== -1) {
        const profile = oldResistivityProfiles.splice(index, 1)[0];
        return {
          ...state,
          resistivityProfiles: [
            ...oldResistivityProfiles,
            {
              ...profile,
              status: RequestStatus.LOADING,
            },
          ],
        };
      } else {
        return state;
      }
    },
    [GET_RESISTIVITY_PROFILE_SUCCEEDED]: (state, action) => {
      const index = state.resistivityProfiles.findIndex(
        (item) => item.locationId === action.payload.locationId
      );
      let oldResistivityProfiles = state.resistivityProfiles;
      if (index !== -1) {
        const profile = oldResistivityProfiles.splice(index, 1)[0];
        return {
          ...state,
          resistivityProfiles: [
            ...oldResistivityProfiles,
            {
              ...profile,
              status: RequestStatus.SUCCEEDED,
              data: action.payload.data,
            },
          ],
        };
      } else {
        return state;
      }
    },
    [GET_RESISTIVITY_PROFILE_FAILED]: (state, action) => {
      const index = state.resistivityProfiles.findIndex(
        (item) => item.locationId === action.payload.locationId
      );
      let oldResistivityProfiles = state.resistivityProfiles;
      if (index !== -1) {
        const profile = oldResistivityProfiles.splice(index, 1)[0];
        return {
          ...state,
          resistivityProfiles: [
            ...oldResistivityProfiles,
            {
              ...profile,
              status: RequestStatus.FAILED,
              error: action.payload.error,
            },
          ],
        };
      } else {
        return state;
      }
    },
    [RAISE_RESISTIVITY_PROFILE]: (state, action) => {
      const index = state.resistivityProfiles.findIndex(
        (item) => item.locationId === action.payload
      );
      let oldResistivityProfiles = state.resistivityProfiles;
      if (index !== -1) {
        const profile = oldResistivityProfiles.splice(index, 1)[0];
        return {
          ...state,
          resistivityProfiles: [...oldResistivityProfiles, profile],
        };
      } else {
        return state;
      }
    },
    [TOGGLE_RESISTIVITY_PROFILE_SIZE]: (state, action) => {
      const newResistivityProfiles = state.resistivityProfiles.map(
        (profile) => {
          if (profile.locationId === action.payload) {
            return {
              ...profile,
              size:
                profile.size === CHART_SIZE.EXPANDED
                  ? CHART_SIZE.COLLAPSED
                  : CHART_SIZE.EXPANDED,
            };
          } else {
            return profile;
          }
        }
      );
      return {
        ...state,
        resistivityProfiles: newResistivityProfiles,
      };
    },
  },
  INITIAL_STATE
);
