import { useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, Paper, useTheme } from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
  Legend,
} from "recharts";
import styled from "styled-components";
import {
  getInitialResistivityRange,
  getMinDepth,
  resistivityProfileToSeries,
} from "../../helper/resistivityProfileHelper";

import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import { useChartWheel } from "../../hooks/useChartWheel";

const TooltipContainer = styled.div`
  padding: 5px;
`;

const OverChartButtons = styled(Paper)`
  height: 30px;
  width: 30px;
  position: absolute;
  display: flex;
  right 1px;
  top: 1px;
  background: ${({ background }) => background}
`;

const getAxisXDomain = (series, from, to) => {
  let left = Number.MAX_SAFE_INTEGER;
  let right = Number.MIN_SAFE_INTEGER;
  for (const { data } of series) {
    for (const { value, depth } of data) {
      if (depth >= from && depth <= to) {
        left = Math.min(left, value);
        right = Math.max(right, value);
      }
    }
  }
  return [left, right];
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <p>Depth: {payload[0].payload.depth}</p>
        <p>Resistivity: {payload[0].payload.value}</p>
      </TooltipContainer>
    );
  }

  return null;
};

const MAX_DEPTH = 30;

export const ResistivityProfileChartRecharts = ({ data, color }) => {
  const theme = useTheme();

  const minDepth = useMemo(() => {
    return getMinDepth(data);
  }, [data]);
  const [top, setTop] = useState(minDepth);
  const [bottom, setBottom] = useState(MAX_DEPTH);
  const [left, setLeft] = useState("dataMin");

  const resistivityRange = useMemo(() => {
    const value = data && getInitialResistivityRange(data);
    return Number.isFinite(value) ? value : "dataMax + 1";
  }, [data]);
  const [right, setRight] = useState(resistivityRange[1]);
  const series = useMemo(
    () => (data ? resistivityProfileToSeries(data) : []),
    [data]
  );
  const [refAreaTop, setRefAreaTop] = useState(null);
  const [refAreaBottom, setRefAreaBottom] = useState(null);
  const [activeLabel, setActiveLabel] = useState();

  useEffect(() => {
    setRight(resistivityRange[1]);
  }, [resistivityRange]);

  useEffect(() => {
    setTop(minDepth);
  }, [minDepth]);

  const fontStyle = useMemo(
    () => ({
      ...theme.typography.caption,
    }),
    [theme]
  );

  const tooltipStyle = useMemo(
    () => ({
      ...fontStyle,
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      zIndex: 1,
    }),
    [theme, fontStyle]
  );

  const legendStyle = useMemo(
    () => ({
      ...fontStyle,
      marginBottom: "10px",
    }),
    [fontStyle]
  );

  const onWheelHandler = (value) => {
    const delta = value * 0.1;
    const ratio = (activeLabel - top) / (bottom - top);
    const newTop = top + Math.round(delta * ratio);
    const newBottom = bottom - Math.round(delta * (1 - ratio));
    if (newTop >= newBottom) {
      return;
    }
    setTop(Math.max(newTop, minDepth));
    setBottom(Math.min(newBottom, MAX_DEPTH));
  };
  const { responsiveContainerRef } = useChartWheel(onWheelHandler);

  const formatAxisValue = (value) => {
    return value.toFixed(2);
  };

  const onMouseMoveHandler = useCallback(
    (e) => {
      setActiveLabel(e.activeLabel);
      Number.isFinite(refAreaTop) && setRefAreaBottom(e.activeLabel);
    },
    [refAreaTop]
  );

  const onMouseDownHandler = useCallback(
    (e) => e?.activeLabel && setRefAreaTop(e.activeLabel),
    []
  );

  const zoom = useCallback(() => {
    if (refAreaTop === refAreaBottom || refAreaBottom === null) {
      setRefAreaTop(null);
      setRefAreaBottom(null);
      return;
    }

    // yAxis domain
    const newRefAreaTop = Math.min(refAreaTop, refAreaBottom);
    const newRefAreaBottom = Math.max(refAreaTop, refAreaBottom);
    setRefAreaTop(null);
    setRefAreaBottom(null);
    setTop(newRefAreaTop);
    setBottom(newRefAreaBottom);

    // xAxis domain
    const [left, right] = getAxisXDomain(
      series,
      newRefAreaTop,
      newRefAreaBottom
    );
    setLeft(left);
    setRight(right);
  }, [refAreaTop, refAreaBottom, series]);

  const zoomOut = useCallback(() => {
    setRefAreaTop(null);
    setRefAreaBottom(null);
    setTop(minDepth);
    setBottom(MAX_DEPTH);
    setLeft("dataMin");
    setRight(resistivityRange[1]);
  }, [resistivityRange, minDepth]);

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height="100%"
        ref={responsiveContainerRef}
      >
        <LineChart
          width="100%"
          height="100%"
          layout="vertical"
          margin={{
            top: 0,
            right: 25,
            left: -10,
            bottom: 5,
          }}
          onMouseDown={onMouseDownHandler}
          onMouseMove={onMouseMoveHandler}
          onMouseUp={zoom}
        >
          <CartesianGrid stroke="#cdcdcd" strokeOpacity={0.8} />
          <XAxis
            allowDataOverflow
            tick={fontStyle}
            tickFormatter={formatAxisValue}
            type="number"
            domain={[left, right]}
            scale="log"
          />
          <YAxis
            allowDataOverflow
            allowDecimals={false}
            interval={0}
            tick={fontStyle}
            tickFormatter={formatAxisValue}
            dataKey="depth"
            type="number"
            domain={[top, bottom]}
          />
          <Tooltip wrapperStyle={tooltipStyle} content={<CustomTooltip />} />
          {series.map(({ data, name, color, type }, index) => (
            <Line
              dataKey="value"
              type={type}
              data={data}
              name={name}
              key={name}
              stroke={color}
            />
          ))}
          {Number.isFinite(refAreaTop) && Number.isFinite(refAreaBottom) ? (
            <ReferenceArea
              y1={refAreaTop}
              y2={refAreaBottom}
              strokeOpacity={0.3}
            />
          ) : null}
          <Legend
            wrapperStyle={legendStyle}
            align="right"
            verticalAlign="top"
            height={36}
          />
        </LineChart>
      </ResponsiveContainer>
      <OverChartButtons background={color}>
        <IconButton size="small" aria-label="close" onClick={zoomOut}>
          <ZoomOutIcon />
        </IconButton>
      </OverChartButtons>
    </>
  );
};
