import { useCallback, useMemo } from "react";
import { useTheme } from "@material-ui/core";
import styled from "styled-components";
import Chart from "react-apexcharts";
import {
  getInitialDepthRange,
  getInitialResistivityRange,
  getResistivityRange,
  resistivityProfileToApexSeries,
} from "../../helper/resistivityProfileHelper";

const ApexContainer = styled.div`
  width: 100%;
  height: 100%;

  & .apexcharts-menu {
    ${({ theme }) => `
      background: ${theme?.palette?.background?.default};
      color: ${theme?.palette?.text?.secondary};
      font-family: ${theme?.typography?.caption?.fontFamily};
      font-weight: ${theme?.typography?.caption?.fontWeight};

      & > div:hover {
        background: ${theme?.palette?.action?.hover};
      }
    `}
  }
`;

const MIN_DEPTH_INIT = 0;
const MAX_DEPTH_INIT = 30;
const LINE_COLORS = ["#42A4F5", "#FF6A61"];

const formatAxisValue = (value) => {
  return isNaN(value) ? "" : value.toFixed(2);
};

const formatDepthValue = (value) => `Depth: ${formatAxisValue(value)}`;

const getYAxis = (fontStyle, minResistivity = 1, maxResistivity) => ({
  labels: {
    formatter: formatAxisValue,
    style: {
      fontFamily: fontStyle.fontFamily,
      fontWeight: fontStyle.fontWeight,
    },
  },
  title: {
    text: "Resistivity (Ohm-m)",
    style: {
      fontFamily: fontStyle.fontFamily,
      fontWeight: fontStyle.fontWeight,
    },
  },
  min: minResistivity || 1,
  max: maxResistivity,
  logarithmic: true,
});

const getXAxis = (fontStyle, min = MIN_DEPTH_INIT, max = MAX_DEPTH_INIT) => ({
  type: "numeric",
  labels: {
    formatter: formatAxisValue,
    style: {
      fontFamily: fontStyle.fontFamily,
      fontWeight: fontStyle.fontWeight,
    },
  },
  title: {
    text: "Depth (m)",
    style: {
      fontFamily: fontStyle.fontFamily,
      fontWeight: fontStyle.fontWeight,
    },
    offsetY: 65,
  },
  min,
  max,
});

export const ResistivityProfileChartApex = ({ data, color }) => {
  const theme = useTheme();

  const fontStyle = useMemo(
    () => ({
      ...theme.typography.caption,
    }),
    [theme]
  );

  const resistivityRange = useMemo(() => {
    const value =
      data &&
      getInitialResistivityRange(data, [MIN_DEPTH_INIT, MAX_DEPTH_INIT]);
    return value?.length ? value : [];
  }, [data]);

  const depthRange = useMemo(() => {
    const value = data && getInitialDepthRange(data);
    return value?.length ? value : [];
  }, [data]);

  const series = useMemo(
    () => (data ? resistivityProfileToApexSeries(data) : []),
    [data]
  );

  const rescaleYAxis = useCallback(
    (chartContext, { xaxis }) => {
      let xMin =
        isFinite(xaxis.min) && xaxis.min >= depthRange[0]
          ? xaxis.min
          : depthRange[0];
      let xMax =
        isFinite(xaxis.max) && xaxis.max <= depthRange[1]
          ? xaxis.max
          : depthRange[1];
      const [min, max] =
        isFinite(xaxis.min) && isFinite(xaxis.max)
          ? getResistivityRange(series, { max: xMax, min: xMin })
          : resistivityRange;
      chartContext.updateOptions({
        yaxis: getYAxis(fontStyle, min, max),
      });
      chartContext.updateOptions({
        xaxis: getXAxis(fontStyle, xMin, xMax),
      });
    },
    [series, fontStyle, resistivityRange, depthRange]
  );

  const beforeResetZoomHandler = () => {
    return {
      xaxis: {
        min: MIN_DEPTH_INIT,
        max: MAX_DEPTH_INIT,
      },
    };
  };

  const options = useMemo(
    () => ({
      chart: {
        type: "line",
        stacked: false,
        zoom: {
          type: "x",
          enabled: true,
        },
        width: "100%",
        height: "100%",
        toolbar: {
          autoSelected: "zoom",
        },
        redrawOnParentResize: true,
        events: {
          zoomed: rescaleYAxis,
          scrolled: rescaleYAxis,
          beforeResetZoom: beforeResetZoomHandler,
        },
      },
      colors: LINE_COLORS,
      stroke: {
        curve: ["stepline", "straight"],
        width: 1,
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: 25,
        fontFamily: fontStyle.fontFamily,
        fontWeight: fontStyle.fontWeight,
        markers: {
          fillColors: LINE_COLORS,
        },
      },
      markers: {
        size: 3,
      },
      yaxis: getYAxis(fontStyle, ...resistivityRange),
      xaxis: getXAxis(fontStyle),
      tooltip: {
        shared: true,
        theme: "dark",
        x: {
          formatter: formatDepthValue,
        },
        y: {
          formatter: formatAxisValue,
        },
      },
    }),
    [resistivityRange, fontStyle, rescaleYAxis]
  );

  return (
    <ApexContainer theme={theme}>
      <Chart options={options} series={series} width="100%" height="100%" />
    </ApexContainer>
  );
};
