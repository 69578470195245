export const resistivityProfileToSeries = ({
  apparent_depths,
  apparent_resistivities,
  depths,
  resistivities,
}) => {
  return [
    {
      name: "True resistivity",
      data: zipDepthAndResistivityArrays(depths, resistivities),
      color: "#42A4F5",
      type: "stepBefore",
    },
    {
      name: "Apparent resistivity",
      data: zipDepthAndResistivityArrays(
        apparent_depths,
        apparent_resistivities
      ),
      color: "#FF6A61",
      type: "linear",
    },
  ];
};

export const resistivityProfileToApexSeries = ({
  apparent_depths,
  apparent_resistivities,
  depths,
  resistivities,
}) => {
  return [
    {
      name: "True resistivity",
      data: zipDepthAndResistivityArrays(depths, resistivities, ["x", "y"]),
    },
    {
      name: "Apparent resistivity",
      data: zipDepthAndResistivityArrays(
        apparent_depths,
        apparent_resistivities,
        ["x", "y"]
      ),
    },
  ];
};

const getMinRangeValue = (minData) => {
  const minBase = Math.floor(Math.log10(minData));
  minData = Math.floor(minData / 10 ** minBase) * 10 ** minBase;
  return minData - 10 ** minBase;
};

const getMaxRangeValue = (maxData) =>
  maxData + 10 ** Math.ceil(Math.log10(maxData));

export const getInitialDepthRange = ({ depths }) => {
  const sortedDepths = [...depths].sort((a, b) => b - a);
  if (sortedDepths.length > 1) {
    return [sortedDepths[sortedDepths.length - 1], sortedDepths[0]];
  }
  return [];
};

export const getInitialResistivityRange = (
  data,
  initialDepthRange = [0, 30]
) => {
  const { depths, resistivities } = data;
  const [minDepth, maxDepth] = initialDepthRange;
  const resistivityWithDepthArray = zipDepthAndResistivityArrays(
    depths,
    resistivities
  );
  const sortedResistivities = resistivityWithDepthArray
    .filter(({ depth }) => depth >= minDepth && depth <= maxDepth)
    .sort((a, b) => b.value - a.value);
  if (sortedResistivities.length > 1) {
    const minData = getMinRangeValue(
      sortedResistivities[sortedResistivities.length - 1].value
    );
    let maxData = getMaxRangeValue(sortedResistivities[1].value);
    if (maxData <= 100) {
      maxData = 100;
    } else {
      maxData = 1000;
    }
    return [minData, maxData];
  }
  return [];
};

export const getResistivityRange = (series, { min, max }) => {
  let minResistivity = Number.MAX_SAFE_INTEGER;
  let maxResistivity = Number.MIN_SAFE_INTEGER;
  for (const aSeries of series) {
    for (const tuple of aSeries.data) {
      if (tuple.x >= min && tuple.x <= max) {
        if (tuple.y < minResistivity) {
          minResistivity = tuple.y;
        }
        if (tuple.y > maxResistivity) {
          maxResistivity = tuple.y;
        }
      }
    }
  }
  if (maxResistivity <= 100) {
    maxResistivity = 100;
  } else {
    maxResistivity = 1000;
  }
  if (minResistivity < 0.01) {
    minResistivity = 0.001;
  } else if (minResistivity < 0.1) {
    minResistivity = 0.01;
  } else if (minResistivity < 1) {
    minResistivity = 0.1
  } else {
    minResistivity = 1;
  }
  return [minResistivity, maxResistivity];
};

export const getMinDepth = ({ depths }) => {
  if (depths.length > 0) {
    return depths[0];
  }
  return 0;
};

const zipDepthAndResistivityArrays = (
  depths,
  resistivities,
  keys = ["depth", "value"]
) => {
  const data = [];
  for (let i = 0; i < depths.length; i++) {
    const depth = depths[i];
    const resistivity = resistivities[i];
    if (!isFinite(resistivity)) {
      break;
    }
    data.push({
      [keys[0]]: depth,
      [keys[1]]: resistivity,
    });
  }
  return data;
};
