import { createSelector } from "reselect";
import { RequestStatus } from "../../constants";

export const selectProjects = createSelector(
  (state) => {
    return state.api.projects;
  },
  (result) => result
);

const selectProjectStatus = (state) => state.api.project.status;
const selectDesignFetchStatus = (state) => state.api.design.fetchingStatus;
const selectDesignUnpackingStatus = (state) => state.api.design.unpackingStatus;
export const selectIsProjectLoadingSucceeded = createSelector(
  selectProjectStatus,
  selectDesignFetchStatus,
  selectDesignUnpackingStatus,
  (projectStatus, designStatus, unpackingStatus) =>
    projectStatus === RequestStatus.SUCCEEDED &&
    designStatus === RequestStatus.SUCCEEDED &&
    unpackingStatus === RequestStatus.SUCCEEDED
);
export const selectIsProjectInLoading = createSelector(
  selectProjectStatus,
  selectDesignFetchStatus,
  selectDesignUnpackingStatus,
  (...statuses) => {
    return statuses.includes(RequestStatus.LOADING);
  }
);

const selectMapConfig = (state) => state.api.project.parsedConfig;
export const selectAdminDatasets = createSelector(
  (state) => state.api.project.adminDatasets,
  (result) => result
);
export const selectAdminDatasetIds = createSelector(
  selectAdminDatasets,
  (result) => result.map((dataset) => dataset.info.id)
);
export const selectUserDatasets = createSelector(
  (state) => state.api.project.userDatasets,
  (result) => result
);
const selectDesignDatasetData = (state) => state.api.design.data;
export const selectProjectData = createSelector(
  selectMapConfig,
  selectAdminDatasets,
  selectUserDatasets,
  selectDesignDatasetData,
  (config, adminDatasets, userDatasets, designDatasetData) => ({
    config,
    extraDatasets: [...adminDatasets, ...userDatasets],
    designDatasetData,
  })
);

export const selectProjectLoadingProgress = createSelector(
  (state) => state.api.design.progress,
  (state) => state.api.design.unpackingProgress,
  (...results) =>
    results.reduce((accum, value) => accum + value, 0) / results.length
);

export const selectResistivityProfiles = createSelector(
  (state) => state.api.resistivityProfiles,
  (result) => result
);
