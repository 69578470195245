import React from "react";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();

  const handletest = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <div class="error-container">
        <div class="row">
          <div class="col-md-12">
            <div class="error-template">
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div class="error-details">
                Sorry, an error has occured, Requested page not found!
              </div>
              <div class="error-actions">
                <button
                  class="btn btn-primary btn-lg"
                  onClick={() => handletest()}
                >
                  Go to home page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default NotFound;
