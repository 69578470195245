import { keplerGlReducer } from "kepler.gl/reducers";
import { combineReducers } from "redux";
import { userReducer } from "./userReducers";
import { appReducer } from "./appReducer";
import {apiReducer} from './apiReducer';

const customizedKeplerGlReducer = keplerGlReducer.initialState({
  uiState: {
    readOnly: false,
    currentModal: false,
    mapControls: {
      visibleLayers: {
        show: true,
      },
      mapLegend: {
        show: true,
        active: false,
      },
      toggle3d: {
        show: true,
      },
      splitMap: {
        show: true,
      },
      mapLocale: {
        show: true,
      },
      mapDraw: {
        show: true,
      },
    },
  },
});
const RootReducer = combineReducers({
  keplerGl: customizedKeplerGlReducer,
  user: userReducer,
  app: appReducer,
  api: apiReducer
});
export default RootReducer;
