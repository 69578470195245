import { handleActions } from "redux-actions";
import { RequestStatus, UiTabs } from "../../constants";
import {
  GET_AUTH0_TOKEN_FAILED,
  GET_AUTH0_TOKEN_LOADING,
  GET_AUTH0_TOKEN_SUCCEEDED,
  SET_ACTIVE_PROJECT,
  SET_ACTIVE_TAB,
  SET_AUTH0_USER,
  TOGGLE_SHOW_RESISTIVITY_PROFILES,
} from "../actions/appActions";

const INITIAL_STATE = {
  activeTab: UiTabs.PROJECTS,
  activeProject: null,
  showResistivityProfiles: false,
  auth0User: null,
  auth0Token: {
    data: null,
    status: RequestStatus.IDLE,
    error: null,
  },
};

export const appReducer = handleActions(
  {
    [SET_ACTIVE_TAB]: (state, action) => {
      return {
        ...state,
        activeTab: action.payload,
      };
    },
    [SET_ACTIVE_PROJECT]: (state, action) => {
      return {
        ...state,
        activeProject: action.payload,
      };
    },
    [TOGGLE_SHOW_RESISTIVITY_PROFILES]: (state, action) => {
      return {
        ...state,
        showResistivityProfiles: !state.showResistivityProfiles,
      };
    },
    [SET_AUTH0_USER]: (state, action) => {
      return {
        ...state,
        auth0User: action.payload,
      };
    },
    [GET_AUTH0_TOKEN_LOADING]: (state, action) => {
      return {
        ...state,
        auth0Token: {
          data: null,
          status: RequestStatus.LOADING,
          error: null,
        },
      };
    },
    [GET_AUTH0_TOKEN_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        auth0Token: {
          data: action.payload,
          status: RequestStatus.SUCCEEDED,
          error: null,
        },
      };
    },
    [GET_AUTH0_TOKEN_FAILED]: (state, action) => {
      return {
        ...state,
        auth0Token: {
          data: null,
          status: RequestStatus.FAILED,
          error: action.payload,
        },
      };
    },
  },
  INITIAL_STATE
);
