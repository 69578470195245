import { CircularProgress, Paper } from "@material-ui/core";
import styled from "styled-components";
import { CHART_SIZE, RequestStatus } from "../../constants";
import { ResistivityProfileHeader } from "./ResistivityProfileHeader";
import { ResistivityProfileChartApex } from "./ResistivityProfileChartApex";
import { ResistivityProfileChartRecharts } from "./ResistivityProfileChartRecharts";
import { useMemo } from "react";

const Container = styled(Paper)`
  ${({ size, width, height }) => {
    if (size === CHART_SIZE.COLLAPSED) {
      return `
        width: ${width};
        height: ${height};
      `;
    } else {
      return `
        /* Multiply sqrt(2) to get (2 * area) */
        --sqrtFrom2: 1.4142135623730950488016887242097;
        width: calc(${width} * var(--sqrtFrom2));
        height: calc(${height} * var(--sqrtFrom2));
      `;
    }
  }}
  transition: width 0.5s, height 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
`;

const Content = styled.div`
  position: relative;
  width: calc(100% - 10px);
  height: calc(100% - 45px);
  margin: 40px 5px 5px 5px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const chartLibrary = process.env.REACT_APP_CHART_LIBRARY || "APEX"; // 'APEX' or 'RECHARTS'

export const ResistivityProfile = (props) => {
  const { status, color, size } = props;

  const containerWidth = useMemo(
    () => (chartLibrary === "APEX" ? "35vw" : "12.5vw"),
    []
  );
  const containerHeight = useMemo(
    () => (chartLibrary === "APEX" ? "35vh" : "50vh"),
    []
  );

  return (
    <Container
      background={color}
      size={size}
      width={containerWidth}
      height={containerHeight}
    >
      <ResistivityProfileHeader {...props} />
      <Content>
        {status === RequestStatus.LOADING && <CircularProgress />}
        {status === RequestStatus.SUCCEEDED &&
          ((chartLibrary === "APEX" && (
            <ResistivityProfileChartApex {...props} />
          )) ||
            (chartLibrary === "RECHARTS" && (
              <ResistivityProfileChartRecharts {...props} />
            )))}
      </Content>
    </Container>
  );
};
