import { makeStyles } from "@material-ui/core/styles";
const drawerWidth = 200;

export const mainScreenStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: -2,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    padding: theme.spacing(0),
    backgroundColor: "#29323C",
    height: "100%",
    width: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export const projectStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: "100vh",
    width: "100%",
    padding: `${theme.spacing(2)}px`,
    boxSizing: "border-box",
    overflow: "auto",
  },
  media: {
    height: 120,
  },
}));

export const mapStyle = makeStyles((theme) => ({
  mapWithDrawer: {
    position: "absolute",
    height: "100%",
    transition: "width 500ms ease",
    overflow: "hidden",
  },
  mapWithDrawerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  mapWithDrawerClose: {
    width: `calc(100% - ${theme.spacing(7) + 1}px)`,
  },
}));

export const loadingStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
