import { ActionTypes } from "kepler.gl/actions";
import { KeplerGlSchema } from "kepler.gl/schemas";
import {
  DESIGN_DATASET_INFO,
  KEPLER_ID,
  LOCATION_DATASET_PREFIX,
} from "../../constants";
import { fetchDeleteDatasets, fetchSaveDatasets } from "../actions/apiActions";
import { switchResistivityProfile } from "../actions/appActions";

const actionInterceptors = {
  [ActionTypes.LOAD_FILES_SUCCESS]: (store, next) => {
    const prevState = store.getState();
    const previousDatasetIds = Object.keys(
      prevState.keplerGl[KEPLER_ID].visState.datasets || {}
    );
    return {
      callback: () => {
        const state = store.getState();
        const keplerGl = state?.keplerGl?.[KEPLER_ID];
        const datasets = KeplerGlSchema.getDatasetToSave(keplerGl);
        const finalParsedDatasets = KeplerGlSchema.parseSavedData(datasets);
        const filteredDatasets = finalParsedDatasets.filter(
          (item) => !previousDatasetIds.includes(item?.info?.id)
        );
        next(fetchSaveDatasets(filteredDatasets));
      },
    };
  },
  [ActionTypes.OPEN_DELETE_MODAL]: (store, next, action) => {
    const datasetId = action.payload.payload || "";
    if (
      datasetId.includes(LOCATION_DATASET_PREFIX) ||
      datasetId === DESIGN_DATASET_INFO.id
    ) {
      return { stop: true };
    }
  },
  [ActionTypes.REMOVE_DATASET]: (store, next, action) => {
    const datasetId = action.payload?.dataId;
    if (!datasetId) {
      return;
    }
    next(fetchDeleteDatasets([datasetId]));
  },
  [ActionTypes.LAYER_CLICK]: (store, next, action) => {
    const state = store.getState();
    if (state.app.showResistivityProfiles) {
      next(switchResistivityProfile(action.payload.info));
      return {
        stop: true,
      };
    }
  },
};

export const interceptActionMiddleware = (store) => (next) => (action) => {
  const interceptor = actionInterceptors[action.type];
  let interceptorResult;
  if (interceptor) {
    interceptorResult = interceptor(store, next, action);
  }
  const { callback, stop } = interceptorResult || {};
  if (stop) {
    if (callback) {
      callback();
    }
    return null;
  } else {
    if (callback) {
      const originalDispatch = next(action);
      originalDispatch.then(() => callback());
      return originalDispatch;
    } else {
      return next(action);
    }
  }
};
